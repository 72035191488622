'use strict';

import jQuery from "jquery";
import i18n from "./i18n";

export default {
  viewPassword: function (context) {
      const password_buttons = context.$el.find('.shwpd');
      password_buttons.each(function () {
          let el = jQuery(this);
         el.on('click', function () {
             el.toggleClass('eye-close eye-open');
            if (el.hasClass('eye-open')) {
                el.siblings(':password')
                    .prop('type', 'text');
                el.attr('aria-label', i18n.get('hide_password'));
            }
            else if (el.hasClass('eye-close')) {
                el.siblings(':text')
                    .prop('type', 'password');
                el.attr('aria-label', i18n.get('show_password'));
            }
         });
      });
  }
};
