'use strict';

import jQuery from 'jquery';
import Backbone from 'backbone';
import fetchCache from 'backbone-fetch-cache';
import Marionette from 'backbone.marionette';
import FastClick from 'fastclick';

import RootView from './views/root';

import ConfigModel from './models/config';
import I18n from "./utils/i18n";
import PushNotifications from './utils/pushnotifications';
import Session from './utils/session';
import * as Dialogs from './utils/dialogs';
import FormValidator from './utils/formvalidator';

import PushController from './pushcontroller';
import Router from './router';

let Application = Marionette.Application.extend({
  region: '#root',
  pathList: [],

  config: new ConfigModel(),

  name: 'MyBraincoach',
  kioskMode: !!parseInt('@ENV.KIOSK_MODE@', 10),
  language: '@ENV.LANGUAGE@',
  intLang: '@ENV.INTLANG@',
  SCORE_IMPROVE: 'improve',
  SCORE_MAINTAIN: 'maintain',

  currentPath: '',

  body: undefined,

  pushNotifications: null,

  currentPageLocked: false,

  onStart: function () {
    let app = this;

    if (typeof window.device !== 'undefined' && window.device.platform === 'iOS') {
      jQuery('body').addClass('is-platform-ios');
    }

    this.body = jQuery('body');

    app.isBusy(true);
    this.setupBowser();
    FastClick(document.body);
    FormValidator();

    if (window.cordova) {
      document.addEventListener('offline', this.onOffline.bind(this), false);
      document.addEventListener('online', this.onOnline.bind(this), false);
      document.addEventListener('backbutton', this.onBack.bind(this), false);
    }

    Backbone.ajax = function (request) {
      return Session.request(request.type, request.url, undefined, request);
    };

    this.showView(new RootView());
    this.router = new Router();

    // Start app after validating the user's Session.
    Session.validate()
      .then(function () {
        return app.loadGlobalData();
      })
      .catch(
        function () {
          app.router.navigate('', {trigger: true});
          return app.loadGlobalData();
        }
      )
      .then(function () {
        Backbone.history.start();
        app.isBusy(false);

        if (window.cordova) {
          setTimeout(function () {
            navigator.splashscreen.hide();
          }, 666);
        }
      });

     this.linkNavigation = false;
  },

  setupBowser: function () {
    let browserName = bowser.name.toLowerCase();
    jQuery('html').addClass(browserName);
  },

  onOffline: function (event) {
    this.isOffline(true);
  },

  onOnline: function (event) {
    this.isOffline(false);
  },

  onBack: function (event) {
    if (navigator.connection.type == window.Connection.NONE) {
        return;
    }

    navigator.app.backHistory();
  },

  /**
   * Sets the offline state.
   */
  isOffline: function(offline) {
    if (offline) {
      jQuery('#offline .message').text(I18n.get('connection_msg'));
      this.body.addClass('is-offline');
    } else {
      this.body.removeClass('is-offline');
    }
  },

  loadGlobalData: function() {
    let app = this;
    return Promise.all([
      app.config.fetch({
        cache: true,
        expires: 60 * 60 * 24
      }),
    ]);
  },

  initPushNotifications: function () {
    if (!this.pushNotifications) {
      this.pushNotifications = new PushNotifications();
      this.pushNotifications.init();
      this.pushNotifications.setController(PushController);
    }
  },

  unregisterPushNotifications: function () {
    if (this.pushNotifications) {
      this.pushNotifications.unregister();
      this.pushNotifications = undefined;
    }
  },

  /**
   * Transitions to another ContentView view inside the app's root view.
   *
   * @param  ContentView view The view to transition to.
   */
  goto: function (view, animateBack) {
    this.isBusy(false);

    let path = Backbone.history.fragment;
    let navigatedBack = false;

    // Detect navigating back to the same view and deny it.
    if (path === this.pathList[this.pathList.length - 1]) {
      return;
    }

    // If the user is not logged in and visiting a restricted page, redirect to the login page.
    if (!Session.isLoggedIn && !view.getOption('isPublic') && path !== 'login') {
      if (!this.kioskMode) {
        this.currentPath = 'login';
        this.router.navigate('login', {trigger: true});
      } else {
        this.currentPath = 'home';
        this.router.navigate('home', {trigger: true});
      }
      return;
    }

    if (this.currentPageLocked) {
      return;
    }

    // Detect if the user navigated back by comparing the new view path to the previous one.
    if (!this.linkNavigation && this.pathList.length > 1 && this.pathList[this.pathList.length - 2] === path) {
      this.pathList = this.pathList.slice(0, -1);
      navigatedBack = true;
    } else {
      this.pathList.push(path);
    }

    // Show the view in the AnimatedRegion.
    let region = this.getView().getRegion('content');
    if (typeof animateBack !== 'undefined') {
      navigatedBack = animateBack;
    }
    let animateClass = navigatedBack ? 'animate-backward' : 'animate-forward';
    region.show(view, {animateClass: animateClass});

    // Reset link navigation to it's default.
    this.linkNavigation = false;

    this.currentPath = path;
  },

  clearCache: function () {
    window.localStorage.setItem(Backbone.fetchCache.getLocalStorageKey(), '');
    Backbone.fetchCache.reset();
  },

  refreshHeader: function (data) {
    if (typeof data === 'undefined') {
      data = {};
    }

    this.getView().getChildView('header').triggerMethod('header:refresh', data);
  },

  /**
   * Sets the busy state of the app.
   *
   * @param  Boolean  busy
   */
  isBusy: function (busy) {
    if (busy) {
      jQuery('body').addClass('is-busy');
    } else {
      jQuery('body').removeClass('is-busy');
    }
  }
});

export default new Application();
