'use strict';

import _ from 'underscore';
import jQuery from 'jquery';
import Marionette from 'backbone.marionette';
import ContentView from '../views/content';
import BackMixin from '../utils/backmixin.js';
import Session from '../utils/session';

import PageModel from '../models/page';
import App from "../app";

let InfoContentView = Marionette.View.extend({
  template: 'info_content',

  onAttach: function (options) {
    this.listenTo(this.model, 'sync', this.render);
    this.model.fetch({cache: true, expires: 60 * 60});
  }
});

export default ContentView.extend({
  template: 'info',
  isPublic: true,

  ui: {
    dropdownLink: '.dropdown-menu li span',
    dropdownTitle: '.dropdown-toggle span'
  },

  events: {
    'click @ui.dropdownLink': 'selectPage'
  },

  childViewEvents: {
    'render': 'childViewRender'
  },

  regions: {
    content: '.contentContainer'
  },

  onAttach: function (options) {
    ContentView.prototype.onAttach.call(this, options);

    this.listenTo(this.collection, 'sync', this.update);
    this.collection.fetch({cache: true, expires: 60 * 60});
  },

  update: function () {
    this.render();
    let menuItem = this.collection.at(0);
    if (menuItem) {
      this.showChildView('content', new InfoContentView({
        model: new PageModel({ id: menuItem.get('id') })
      }));
    }
  },

  selectPage: function (event) {
    let pageId = jQuery(event.currentTarget).data('id');

    this.showChildView('content', new InfoContentView({
      model: new PageModel({id: pageId})
    }));
  },

  childViewRender: function (childView) {
    this.ui.dropdownTitle.text(childView.model.get('title'));
  },

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, {
      loggedIn: Session.isLoggedIn
    });
  },

  onRender: function () {
    if (Session.isLoggedIn) {
      App.refreshHeader({activeTab: 'info'});
    } else {
      App.refreshHeader({showBackButton: true});
    }
  }

});
