'use strict';

import Marionette from 'backbone.marionette';

import Controller from './controller';

export default Marionette.AppRouter.extend({
  controller: Controller,
  appRoutes: {
    '': 'home',
    'home': 'home',
    'info': 'info',
    'login': 'login',
    'logout': 'logout',
    'forgot-password': 'forgotPassword',
    'reset-password(/:email)(/:temp_password)': 'resetPassword',
    'register(/:sid)': 'register',
    'profile': 'profile',

    'short-libra': 'shortLibra',
    'session/:id': 'startCoachSession',
    'session/:id/:question': 'coachQuestion',
    'short-libra-score': 'shortLibraScore',
    'view-session/:id': 'viewCoachSession',

    'snippet': 'snippet',
    'themes': 'themes',
    'theme/:id': 'themeDetail'
  }
});
