'use strict';

import ContentView from '../views/content';

import _ from 'underscore';
import jQuery from 'jquery';
import * as Dialogs from '../utils/dialogs';
import Session from '../utils/session';
import App from '../app';

import BackMixin from '../utils/backmixin.js';
import FormMixin from '../utils/formmixin.js';
import I18n from '../utils/i18n';
import Validator from 'validatorjs';
import viewpassword from "../utils/viewpassword";

let ResetPasswordView = ContentView.extend({
  template: 'reset_password',
  isPublic: true,

  ui: {
    form: 'form.user-form',
    mail: '#mail',
    temp_password: '#temp_password',
    password: '#password',
    submit: 'button[type="submit"]',
    back: '.back-link'
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.back': 'back'
  },

  onRender: function (options) {
    viewpassword.viewPassword(this);
  },

  submit: function (event) {
    event.preventDefault();
    App.isBusy(true);
    let view = this;

    let formValidationRules = {
      temp_password: 'required|different:password',
      password: ['required', 'min:8', 'confirmed']
    };

    // Validate form.
    let validation = new Validator(this.getFormValues(this.ui.form), formValidationRules);
    validation.setAttributeNames(this.getFormValidationAttributeNames());
    if (validation.passes()) {
      // Submit password change.
      let passwordData = {
        name: this.ui.mail.val(),
        temp_pass: this.ui.temp_password.val(),
        new_pass: this.ui.password.val()
      };

      Session.request('POST', 'user/lost-password-reset', passwordData)
        .done(function (data, status, xhr) {
          App.isBusy(false);
          Dialogs.alert(I18n.get('you_can_login_using_new_password'));
          App.router.navigate('#login', {trigger: true});
        })
        .fail(function (xhr, status, error) {
          App.isBusy(false);
          let data = xhr.responseJSON;
          if (typeof data.message !== 'undefined' && data.message === 'The recovery password is not valid.') {
            Dialogs.alert(I18n.get('error_incorrect_temporary_password'));
          } else {
            let errorMessage = view.getErrorResponseDisplayableErrors(data);
            if (errorMessage !== null) {
              Dialogs.alert(errorMessage);
            } else {
              Dialogs.alert(I18n.get('error_password_requirements'));
            }
          }
        });
    } else {
      Dialogs.alert(this.getFormDisplayableErrors(validation.errors));
      App.isBusy(false);
    }

    return false;
  },

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, {
      mail: this.getOption('mail'),
      tempPassword: this.getOption('tempPassword')
    });
  },

  getFormValidationAttributeNames: function() {
    return {
      temp_password: I18n.get('temp_password'),
      password: I18n.get('new_password'),
    };
  }
});

_.extend(ResetPasswordView.prototype, FormMixin);
_.extend(ResetPasswordView.prototype, BackMixin);

export default ResetPasswordView;
