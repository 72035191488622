'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: 'v2/home',

  defaults: {
    id: 0,
    title: '',
    content: ''
  }
});
