'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    return 'v2/content/' + encodeURIComponent(this.attributes.id);
  },

  defaults: {
    id: 0,
    title: '',
    content: ''
  }
});
