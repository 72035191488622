'use strict';

import Marionette from 'backbone.marionette';

import App from '../app';
import Session from '../utils/session';
import jQuery from "jquery";
import BackMixin from "../utils/backmixin";
import _ from "underscore";

let HeaderView = Marionette.View.extend({
  template: 'header',

  ui: {
    back: '.back-link',
    tabButton: '.tab-button',
  },

  events: {
    'click @ui.back': 'back'
  },

  headerData: {},

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, {
      showHeader: true,
      activeTab: '',
      loggedIn: Session.isLoggedIn,
      showBackButton: false,
      showLoginButton: !App.kioskMode
    }, this.headerData);

    if (this.templateContext.showHeader) {
      jQuery('#content').removeClass('full-height');
    } else {
      jQuery('#content').addClass('full-height');
    }
  },

  onRender: function () {
    let subPage = this.templateContext.activeTab;
    // Select active item
    this.ui.tabButton.each(function () {
      if (subPage === jQuery(this).data('subpage')) {
        jQuery(this).parent().addClass('is-active');
      } else {
        jQuery(this).parent().removeClass('is-active');
      }
    });
  },

  onHeaderRefresh: function (data) {
    this.headerData = data;
    this.render();
  }

});

_.extend(HeaderView.prototype, BackMixin);

export default HeaderView;