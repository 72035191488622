import App from '../app';

class I18n {
  constructor() {
    this.strings = {
      'nl': { //Default
        'see_results': 'Bekijk jouw uitkomsten en activeer de thema\'s waaraan je wilt werken.',
        'activate_this_theme': 'Maak dit thema actief',
        'first_nut_tomorrow': 'Je ontvangt morgen de eerste noot om te kraken.',
        'theme_activated': 'Dit thema is nu actief',
        'rewards_explanation': 'De volgende stap is het bekijken van de thema\'s waarbij er voor jou ruimte voor verbetering is. Selecteer de thema\'s waaraan je wil gaan werken en je ontvangt iedere dag een nieuwe tip, vraag of handig weetje over dit thema.',
        'good_job': 'Goed bezig!',
        'get_started_directly': 'Meteen aan de slag!',
        'theme_switching_explanation': 'Heb je eerder aan een ander thema gewerkt en zou je graag deze noten nog eens teruglezen? Dit kun je doen door dat thema weer even te activeren.',
        'go_to_themes': 'Naar de thema\'s',
        'we_hope_motivation': 'We hopen dat er iets tussen zat wat je motivatie heeft versterkt om je hersengezondheid goed in de gaten te blijven houden. Als je wilt, kun je terugkeren naar je scoreoverzicht en beslissen of je aan een ander thema wilt gaan werken in de komende weken.',
        'bust_nut': 'Kraak de noot',
        'register_for_more': 'Wil je op een leuke manier aan de slag met jouw verbeterpunten en weten hoe je scoort op alle thema’s? Maak dan nu een account aan!',
        'nut_rewards_alt': 'Gekraakte noten',
        'logo_alt': 'Logo MijnBreincoach',
        'logout': 'Uitloggen',
        'you_are_logged_out': 'Je bent nu uitgelogd.',
        'cancel': 'Annuleren',
        'start_app': 'Start MijnBreincoach',
        'save': 'Opslaan',
        'read_privacy_statement': 'Lees hier onze privacyverklaring.',
        'privacy_consent_explanation': 'Deze gegevens zijn nodig om gebruik te maken van MijnBreincoach. De regels voor de opslag en gebruik van de gegevens staan beschreven in de privacyverklaring.',
        'privacy_consent': 'Ik geef MijnBreincoach toestemming om de door mij ingevulde persoonsgegevens te bewaren.',
        'current_password': 'Huidig wachtwoord',
        'phone_number': 'Telefoonnummer',
        'age': 'Leeftijd',
        'birth_date': 'Geboortedatum',
        'postal_code': 'Postcode',
        'postal_code_only_digits': 'Vul alleen de vier cijfers in.',
        'female': 'Vrouw',
        'male': 'Man',
        'other': 'Anders',
        'gender': 'Geslacht',
        'name': 'Naam',
        'subscribe_to_newsletter': 'Schrijf mij in voor de nieuwsbrief',
        'subscribe_to_newsletter_link': 'Schrijf je in voor de nieuwsbrief',
        'make_free_account': 'Ga voor gezonde hersenen! Maak nu een eigen account aan en krijg gratis toegang tot de MijnBreincoach app.',
        'i_will_participate': 'Ik doe mee',
        'join': 'Ik doe mee',
        'profile': 'Profiel',
        'change': 'Verander',
        'password_confirmation': 'Wachtwoord (bevestiging)',
        'hide_password': 'Verberg wachtwoord',
        'show_password': 'Toon wachtwoord',
        'new_password_confirmation': 'Nieuw wachtwoord (bevestiging)',
        'new_password': 'Nieuw wachtwoord',
        'temp_password': 'Tijdelijk wachtwoord',
        'password_requirements_hint': 'Een veilig wachtwoord bestaat uit minimaal 8 karakters, waarvan minstens 1 cijfer, 1 kleine letter, 1 hoofdletter en 1 leesteken.',
        'reset_password_instructions': 'Stel je nieuwe wachtwoord in. Vul hieronder het tijdelijke wachtwoord dat je per e-mail hebt ontvangen in, en vul een nieuw wachtwoord in.',
        'reset_password': 'Wachtwoord opnieuw instellen',
        'forgot_password': 'Wachtwoord vergeten?',
        'change_password': 'Wachtwoord wijzigen',
        'request_new_password': 'Nieuw wachtwoord aanvragen',
        'email': 'E-mailadres',
        'enter_your_email': 'Voer je e-mailadres in.',
        'password_request_sent': 'De wachtwoordaanvraag is verstuurd naar jouw e-mailadres.',
        'error_cannot_complete_password_request': 'De wachtwoordaanvraag kon niet worden voltooid.',
        'enter_email_and_password': 'Voer een e-mailadres en wachtwoord in.',
        'error_email_or_password_incorrect': 'Het e-mailadres of het wachtwoord zijn onjuist.',
        'error_login_failed_check_connection': 'Inloggen is mislukt. Controleer de internetverbinding en probeer het opnieuw.',
        'error_login_failed': 'Kan niet inloggen.',
        'error_profile_save_failed': 'Er is een fout opgetreden bij het opslaan van het profiel.',
        'you_can_login_using_new_password': 'Je kunt nu inloggen met je nieuwe wachtwoord.',
        'error_incorrect_temporary_password': 'Het tijdelijke wachtwoord is niet correct.',
        'error_password_requirements': 'Het wachtwoord voldoet niet aan de eisen.',
        'login': 'Inloggen',
        'completed': 'Voltooid',
        'next': 'Volgende',
        'previous': 'Vorige',
        'stop_test': 'Stoppen met de test',
        'information': 'Informatie',
        'back': 'Terug',
        'and': 'en',
        'ready': 'Klaar!',
        'see_your_results': 'Klaar',
        'close': 'Sluiten',
        'previous_question': 'Vorige vraag',
        'test_result_improve': 'Uit jouw snelle test blijkt dat er ruimte voor verbetering is op het gebied van',
        'test_result_maintain': 'In het groen zie je waar je goed op scoort, namelijk',
        'test_result_keep_up': ', hou dit zo vol!',
        'test_result_good': 'Uit de snelle test blijkt dat je goed bezig bent. De MijnBreincoach app helpt je om je hersengezondheid op peil te houden.',
        'thanks_result_page': 'Bedankt voor het invullen van de snelle test! Wil je op een leuke manier aan de slag met jouw verbeterpunten? Ga dan naar mijnbreincoach.eu of download de app.',
        'bonus_singular': '1 bonuspunt',
        'bonus_plural': '@bonus bonuspunten',
        'theme_completed_with_bonus': 'Gefeliciteerd! Je hebt <strong>alle @rewardTotalCount noten</strong> gekraakt en daarmee <span class="highlighted">@bonus</span> verdiend. Je hebt nu alle noten voor deze thema\'s afgerond.',
        'theme_completed': 'Gefeliciteerd! Je hebt <strong>alle @rewardTotalCount noten</strong> gekraakt. Je hebt nu alle noten voor deze thema\'s afgerond.</p>',
        'first_reward_with_bonus': 'Je hebt nu <strong>de eerste noot</strong> gekraakt en tot nu toe <span class="highlighted">@bonus</span> verdiend. Morgen staat er weer een nieuwe noot voor je klaar!',
        'first_reward': 'Je hebt nu <strong>de eerste noot</strong> gekraakt. Morgen staat er weer een nieuwe noot voor je klaar!',
        'theme_ongoing_with_bonus': 'Je hebt al <strong>@rewardCompletedCount van de @rewardTotalCount noten</strong> gekraakt en tot nu toe <span class="highlighted">@bonus</span> verdiend. Open iedere dag een nieuwe noot!',
        'theme_ongoing': 'Je hebt al <strong>@rewardCompletedCount van de @rewardTotalCount noten</strong> gekraakt. Open iedere dag een nieuwe noot!',
        'retake': 'Test opnieuw invullen',
        'nut_of_the_day': 'Noot van de dag',
        'themes': 'Thema\'s',
        'i_have_an_account': 'Ik heb al een account',
        'app_name': 'MijnBreincoach',

        'connection_msg':'De internetverbinding is verbroken. Om juist te functioneren heeft MijnBreincoach een internetverbinding nodig.',
        'error_find_session':'Geen actieve sessies gevonden.',
        'error_start_session':'Kan geen actieve sessie ophalen.',
        'error_start_test':'Er is een fout opgetreden bij het starten van de LIBRA test.',
        'short_test_restrict':'De korte LIBRA test is niet beschikbaar wanneer je ingelogd bent.',
        'error_test_early_end': 'De test is vroegtijdig geëindigd.',
        'error_test_start':'Er is een fout opgetreden bij het starten van de test.',
        'error_save_theme': 'Er is een fout opgetreden bij het instellen van het thema.',

        'referral_list_work': 'Je werkt op een leuke manier aan gezonde(re) hersenen',
        'referral_list_advice': 'Je krijgt persoonlijk advies',
        'referral_list_subject': 'Je kiest zelf waar, wanneer en met welk onderwerp je aan de slag gaat',
        'referral_list_messgae': 'Je ontvangt iedere dag een kort berichtje: een ‘weetje’, quizvraag, tip of uitdaging',

        'error_accept_privacy': 'Kan het akkoord niet opslaan',
        'navigation_error':'Er is een fout opgetreden bij het navigeren.',
        'error_send_answer':'Er is een fout opgetreden bij het versturen van het antwoord.',
        'min_digits': 'Het :attribute veld moet minimaal :min_digits cijfer(s) bevatten.',
        'min_lowers':'Het :attribute veld moet minimaal :min_lowers kleine letter(s) bevatten.',
        'min_uppers': 'Het :attribute veld moet minimaal :min_uppers hoofdletter(s) bevatten',
        'min_specialchars':'Het :attribute veld moet minimaal :min_specialchars speciale karakter(s) bevatten.',
        'min_selected':'Selecteer minimaal :min_selected opties in het :attribute veld.',
        'max_selected':'Selecteer maximaal :max_selected opties in het :attribute veld.',
        'answer': 'antwoord',

        'form_contains_errors': 'Het formulier bevat de volgende fouten:\n',
        'password': 'Wachtwoord',
        'form_password_too_short': 'Het wachtwoord is te kort.',
        'form_password_too_long': 'Het wachtwoord is te lang.',
        'form_password_no_upper_case': 'Het wachtwoord bevat geen hoofdletters.',
        'form_password_no_lower_case': 'Het wachtwoord bevat geen kleine letters.',
        'form_password_no_numeric': 'Het wachtwoord bevat geen cijfers.',
        'form_password_no_special': 'Het wachtwoord bevat geen speciale karakters.',

        'chatui_ok': 'Okay',
        'chatui_continue': 'Verder',

        'system_notification_ignore': 'Negeer',
        'system_notification_view': 'Bekijk',

        'date_days': '@days',
        'day_sun': 'Zon',
        'day_mon': 'Maa',
        'day_tue': 'Din',
        'day_wed': 'Woe',
        'day_thu': 'Don',
        'day_fri': 'Vrij',
        'day_sat': 'Zat',
        'month_jan': 'Jan',
        'month_feb': 'Feb',
        'month_mar': 'Mrt',
        'month_apr': 'Apr',
        'month_may': 'Mei',
        'month_jun': 'Jun',
        'month_jul': 'Jul',
        'month_aug': 'Aug',
        'month_sep': 'Sep',
        'month_oct': 'Okt',
        'month_nov': 'Nov',
        'month_dec': 'Dec',
        'month_full_jan': 'Januari',
        'month_full_feb': 'Februari',
        'month_full_mar': 'Maart',
        'month_full_apr': 'April',
        'month_full_may': 'Mei',
        'month_full_jun': 'Juni',
        'month_full_jul': 'Juli',
        'month_full_aug': 'Augustus',
        'month_full_sep': 'September',
        'month_full_oct': 'Oktober',
        'month_full_nov': 'November',
        'month_full_dec': 'December',
        'datepicker_overlay_button': 'Versturen',
        'datepicker_overlay_placeholder': 'Voer een jaar in',
      },
      'en': { //English
        'see_results': 'Bekijk jouw uitkomsten en activeer het thema waaraan je gaat werken.',
        'activate_this_theme': 'Maak dit thema actief',
        'first_nut_tomorrow': 'Je ontvangt morgen de eerste noot om te kraken.',
        'theme_activated': 'Dit thema is nu actief',
        'rewards_explanation': 'Bedankt voor het invullen. De volgende stap is het bekijken van de thema\'s waarbij er voor jou ruimte voor verbetering is. Selecteer een thema waaraan je wil gaan werken en je ontvangt iedere dag een nieuwe tip, vraag of handig weetje over dit thema.',
        'good_job': 'Goed bezig!',
        'go_to_themes': 'Naar de thema\'s',
        'we_hope_motivation': 'We hopen dat er iets tussen zat wat je motivatie heeft versterkt om je hersengezondheid goed in de gaten te blijven houden. Als je wilt, kun je terugkeren naar je scoreoverzicht en beslissen of je aan een ander thema wilt gaan werken in de komende weken.',
        'bust_nut': 'Kraak de noot',
        'register_for_more': 'Wil je op een leuke manier aan de slag met jouw verbeterpunten en weten hoe je scoort op alle thema’s? Maak dan nu een account aan!',
        'nut_rewards_alt': 'Gekraakte noten',
        'logo_alt': 'Logo MijnBreincoach',
        'logout': 'Uitloggen',
        'cancel': 'Annuleren',
        'start_app': 'Start MijnBreincoach',
        'save': 'Opslaan',
        'read_privacy_statement': 'Lees hier onze privacyverklaring.',
        'privacy_consent_explanation': 'Deze gegevens zijn nodig om gebruik te maken van MijnBreincoach. De regels voor de opslag en gebruik van de gegevens staan beschreven in de privacyverklaring.',
        'privacy_consent': 'Ik geef MijnBreincoach toestemming om de door mij ingevulde persoonsgegevens te bewaren.',
        'current_password': 'Huidig wachtwoord',
        'phone_number': 'Telefoonnummer',
        'birth_date': 'Birthdate',
        'female': 'Female',
        'male': 'Male',
        'other': 'Other',
        'gender': 'Gender',
        'name': 'Name',
        'make_free_account': 'Ga voor gezonde hersenen! Maak nu een eigen account aan en krijg gratis toegang tot de MijnBreincoach app.',
        'i_will_participate': 'Ik doe mee',
        'join': 'Ik doe mee',
        'profile': 'Profiel',
        'change': 'Verander',
        'password_confirmation': 'Wachtwoord (bevestiging)',
        'new_password_confirmation': 'Nieuw wachtwoord (bevestiging)',
        'new_password': 'Nieuw wachtwoord',
        'temp_password': 'Tijdelijk wachtwoord',
        'password_requirements_hint': 'Een veilig wachtwoord bestaat uit minimaal 8 karakters, waarvan minstens 1 cijfer, 1 kleine letter, 1 hoofdletter en 1 leesteken.',
        'reset_password_instructions': 'Stel je nieuwe wachtwoord in. Vul hieronder het tijdelijke wachtwoord dat je per e-mail hebt ontvangen in, en vul een nieuw wachtwoord in.',
        'reset_password': 'Wachtwoord opnieuw instellen',
        'forgot_password': 'Wachtwoord vergeten?',
        'email': 'E-mail address',
        'login': 'Login',
        'completed': 'Completed',
        'next': 'Next',
        'previous': 'Previous',
        'stop_test': 'Stop this test',
        'information': 'Information',
        'back': 'Back',
        'and': 'and',
        'ready': 'Ready!',
        'see_your_results': 'Ready',
        'close': 'Close',
        'previous_question': 'Previous question',
        'test_result_improve': 'Your short test shows that there is room for improvement in the areas of ',
        'test_result_maintain': 'In green you can see where you are doing well, namely on',
        'test_result_keep_up': ', keep this up!',
        'test_result_good': 'Your short test shows that you are doing well. Keep this up!',
        'thanks_result_page': 'Thank you for completing the short test. We hope that you are motivated to keep your brain fit and that you will take on the challenge to start working on your room-for-improvement.',
        'bonus_singular': '1 bonuspoint',
        'bonus_plural': '@bonus bonuspoints',
        'theme_completed_with_bonus': 'Gefeliciteerd! Je hebt <strong>alle @rewardTotalCount noten</strong> gekraakt en daarmee <span class="highlighted">@bonus</span> verdiend. Je hebt nu alle noten voor deze thema\'s afgerond.',
        'theme_completed': 'Gefeliciteerd! Je hebt <strong>alle @rewardTotalCount noten</strong> gekraakt. Je hebt nu alle noten voor deze thema\'s afgerond.',
        'first_reward_with_bonus': 'Je hebt nu <strong>de eerste noot</strong> gekraakt en tot nu toe <span class="highlighted">@bonus</span> verdiend. Morgen staat er weer een nieuwe noot voor je klaar!',
        'first_reward': 'Je hebt nu <strong>de eerste noot</strong> gekraakt. Morgen staat er weer een nieuwe noot voor je klaar!',
        'theme_ongoing_with_bonus': 'Je hebt al <strong>@rewardCompletedCount van de @rewardTotalCount noten</strong> gekraakt en tot nu toe <span class="highlighted">@bonus</span> verdiend. Open iedere dag een nieuwe noot!',
        'theme_ongoing': 'Je hebt al <strong>@rewardCompletedCount van de @rewardTotalCount noten</strong> gekraakt. Open iedere dag een nieuwe noot!',
        'theme_switching_explanation': 'Have you worked on a different theme before and would you like to read these nuts again? You can do this by activating that theme again.',
        'retake': 'Take the test again',
        'change_password': 'Change password',
        'request_new_password': 'Request new password',
        'postal_code': 'Postal code',
        'postal_code_only_digits': 'Only enter the four digits.',
        'subscribe_to_newsletter': 'Subscribe me to the newsletter',
        'subscribe_to_newsletter_link': 'Schrijf je in voor de nieuwsbrief',
        'nut_of_the_day': 'Nut of the day',
        'themes': 'Themes',
        'i_have_an_account': 'I already have an account',
        'you_are_logged_out': 'You are logged out now.',
        'app_name': 'MyBraincoach',
        'enter_your_email': 'Enter your email address.',
        'password_request_sent': 'The password request has been sent to your email address.',
        'error_cannot_complete_password_request': 'The password request could not be completed.',
        'enter_email_and_password': 'Enter an email address and password.',
        'error_email_or_password_incorrect': 'The email address or password is incorrect.',
        'error_login_failed_check_connection': 'Login failed. Please check your internet connection and try again.',
        'error_login_failed': 'Login failed.',
        'error_profile_save_failed': 'Saving the profile failed.',
        'you_can_login_using_new_password': 'You can now login using your new password.',
        'error_incorrect_temporary_password': 'The temporary password is incorrect.',
        'error_password_requirements': 'The password does not meet the requirements.',

        'connection_msg':'No internet connection. MyBraincoach requires an active connection to function.',
        'error_find_session':'No active session found.',
        'error_start_session':'Cannot load active session.',
        'error_start_test':'An error occurred while starting the LIBRA test.',
        'short_test_restrict':'The short LIBRA test is unavailable when logged in.',
        'error_test_early_end': 'The test ended prematurely.',
        'error_test_start':'An error occurred while starting the test.',
        'error_save_theme': 'An error has been encountered while saving the theme.',

        'referral_list_work': 'Ready to be translated!',
        'referral_list_advice': 'Ready to be translated!',
        'referral_list_subject': 'Ready to be translated!',
        'referral_list_messgae': 'Ready to be translated!',

        'error_accept_privacy': 'error while saving document.',
        'navigation_error':'A navigation error occurred.',
        'error_send_answer':'An error occurred, Answer could not be send.',
        'min_digits': 'The field :attribute should contain a minimum of :min_digits digits.',
        'min_lowers':'The field :attribute  should contain a minimum of :min_lowers lowercase letters.',
        'min_uppers':'The field :attribute  should contain a minimum of :min_uppers Uppercase letters.',
        'min_specialchars':'The field :attribute  should contain a minimum of :min_specialchars special characters.',
        'min_selected':'Select at least :min_selected options in the :attribute field.',
        'max_selected':'Select :max_selected options at maximum in the :attribute field.',
        'answer': 'answer',

        'form_contains_errors': 'The form contains the following errors:\n',
        'password': 'Password',
        'form_password_too_short': 'The password is too short.',
        'form_password_too_long': 'The password is too long.',
        'form_password_no_upper_case': 'The password does not contain any uppercase characters.',
        'form_password_no_lower_case': 'The password does not contain any lower case characters.',
        'form_password_no_numeric': 'The password does not contain any numeric characters.',
        'form_password_no_special': 'The password does not contain any special characters.',

        'chatui_ok': 'Okay',
        'chatui_continue': 'Continue',

        'system_notification_ignore': 'Ignore',
        'system_notification_view': 'View',

        'date_days': '@days',
        'day_sun': 'Sun',
        'day_mon': 'Mon',
        'day_tue': 'Tue',
        'day_wed': 'Wed',
        'day_thu': 'Thu',
        'day_fri': 'Fri',
        'day_sat': 'Sat',
        'month_jan': 'Jan',
        'month_feb': 'Feb',
        'month_mar': 'Mar',
        'month_apr': 'Apr',
        'month_may': 'May',
        'month_jun': 'Jun',
        'month_jul': 'Jul',
        'month_aug': 'Aug',
        'month_sep': 'Sep',
        'month_oct': 'Oct',
        'month_nov': 'Nov',
        'month_dec': 'Dec',
        'month_full_jan': 'January',
        'month_full_feb': 'February',
        'month_full_mar': 'March',
        'month_full_apr': 'April',
        'month_full_may': 'May',
        'month_full_jun': 'June',
        'month_full_jul': 'July',
        'month_full_aug': 'August',
        'month_full_sep': 'September',
        'month_full_oct': 'October',
        'month_full_nov': 'November',
        'month_full_dec': 'December',
        'datepicker_overlay_button': 'Submit',
        'datepicker_overlay_placeholder': 'Enter a year',
      },
      'da': { //Danish
        'see_results': 'Se dine resultater og aktivér den eller de kategorier, du vil arbejde med. Du kan aktivere så mange kategorier, du vil. Hvis din test ikke viser nogle områder med plads til forbedring, kan du stadig aktivere kategorierne, hvis der er nogle områder, du godt kunne tænke dig at få mere inspiration inden for.',
        'activate_this_theme': 'Aktiver kategori',
        'first_nut_tomorrow': 'I morgen vil du modtage det første tip - dagens nød.',
        'theme_activated': 'Denne kategori er aktiv',
        'rewards_explanation': 'Tak for din registrering. Det er nu tid til at se på de områder, hvor du har plads til forbedring. Vælg den eller de kategorier, du vil arbejde med, så vil du hver dag modtage et nyt tip med inspiration, personlige udfordringer og gode råd.',
        'good_job': 'Godt arbejde!',
        'theme_switching_explanation': 'Har du tidligere arbejdet med en anden kategori, og vil du gerne læse disse tips igen? Det kan du gøre ved at aktivere den kategori igen.',
        'go_to_themes': 'Gå til kategorier',
        'we_hope_motivation': 'Vi håber, at du har fundet noget, der gav dig motivation til at holde godt øje med din hjernes sundhed. Du kan gå tilbage til dit resultat og beslutte, om du vil arbejde med en anden kategori de kommende uger.',
        'bust_nut': 'Knæk nødden',
        'register_for_more': 'Få personlig inspiration og daglige tips til at holde din hjerne sund. Tilmeld dig og tag det første skridt nu.',
        'nut_rewards_alt': 'Knækkede nødder',
        'logo_alt': 'Logo Hjernesund-testen',
        'logout': 'Log ud',
        'you_are_logged_out': 'Du er nu logget ud.',
        'cancel': 'Annuller',
        'start_app': 'Få dine tips',
        'save': 'Gem',
        'read_privacy_statement': 'Læs vores privatlivspolitik.',
        'privacy_consent_explanation': 'Disse oplysninger er nødvendige. Du kan læse hvordan vi bruger og opbevarer data i vores privatlivspolitik.',
        'privacy_consent': 'Jeg giver Alzheimerforeningen tilladelse til at gemme de oplysninger, jeg har indtastet ovenfor',
        'current_password': 'Nuværende adgangskode',
        'phone_number': 'Telefonnummer',
        'birth_date': 'Fødselsdato',
        'postal_code': 'Postnummer',
        'postal_code_only_digits': 'Indtast kun de fire cifre.',
        'female': 'Kvinde',
        'male': 'Mand',
        'other': 'Andet',
        'gender': 'Køn',
        'name': 'Navn',
        'subscribe_to_newsletter': 'Skriv mig op til hjernesunde nyheder',
        'subscribe_to_newsletter_link': 'Jeg vil nøjes med at modtage Hjernesund-nyheder',
        'make_free_account': 'Registrer dig til dit personlige forløb nu. Så vil du få daglige tips til at holde din hjerne sund de næste par uger på din mail.',
        'i_will_participate': 'Få dine personlige tips nu',
        'join': 'Ja tak til inspiration',
        'profile': 'Profil',
        'change': 'Rediger',
        'password_confirmation': 'Adgangskode (bekræftelse)',
        'new_password_confirmation': 'Ny adgangskode (bekræftelse)',
        'new_password': 'Ny adgangskode',
        'temp_password': 'Midlertidig adgangskode',
        'password_requirements_hint': 'En sikker adgangskode består af mindst 8 tegn, heraf mindst et tal, et lille bogstav, et stort bogstav og et specialtegn.',
        'reset_password_instructions': 'Indstil din nye adgangskode. Indtast den midlertidige adgangskode, du har modtaget via e-mail herunder, og indtast en ny adgangskode.',
        'reset_password': 'Reset adgangskode',
        'forgot_password': 'Glemt kodeord?',
        'change_password': 'Skift kodeord',
        'request_new_password': 'Bestil ny adgangskode',
        'email': 'E-mail-adresse',
        'enter_your_email': 'Indtast din e-mailadresse.',
        'password_request_sent': 'Anmodningen om adgangskode er blevet sendt til din e-mailadresse.',
        'error_cannot_complete_password_request': 'Anmodningen om adgangskode kunne ikke gennemføres.',
        'enter_email_and_password': 'Indtast en e-mailadresse og adgangskode.',
        'error_email_or_password_incorrect': 'E-mailadressen eller adgangskoden er forkert.',
        'error_login_failed_check_connection': 'Login mislykkedes. Kontroller din internetforbindelse, og prøv igen.',
        'error_login_failed': 'Login mislykkedes.',
        'error_profile_save_failed': 'Der er opstået en fejl under lagring af din profil.',
        'you_can_login_using_new_password': 'Du kan nu logge ind med din nye adgangskode.',
        'error_incorrect_temporary_password': 'Den midlertidige adgangskode er forkert.',
        'error_password_requirements': 'Adgangskoden opfylder ikke kravene.',

        'login': 'Log ind',
        'completed': 'Afsluttet',
        'next': 'Næste',
        'previous': 'Forrige',
        'stop_test': 'Afbryd testen',
        'information': 'Information',
        'back': 'Tilbage',
        'and': 'og',
        'ready': 'Knæk en nød og få dagens tip',
        'see_your_results': 'Se dit resultat og få gode tips',
        'close': 'Luk',
        'previous_question': 'Forrige spørgsmål',
        'test_result_improve': 'Din test viser, at der er plads til forbedringer på området/områderne',
        'test_result_maintain': 'Til gengæld klarer du det godt med hensyn til:',
        'test_result_keep_up': '. Fortsæt den gode stil!',
        'test_result_good': 'Testen viser, at du klarer det godt. Stærkt! Få inspiration til at holde den gode stil i linket ovenfor.',
        'thanks_result_page': 'Tak fordi du gennemførte testen. Er du klar til at tage udfordringen op og begynde at arbejde med at holde din hjerne sund? Du kan tilmelde dig vores hjernesunde tip med inspiration, personlige challenges, og gode råd.',
        'bonus_singular': '1 bonuspoint',
        'bonus_plural': '@bonus bonuspoint',
        'theme_completed_with_bonus': 'Tillykke! Du har knækket <strong>alle @rewardTotalCount nødder</strong> og tjent <span class="highlighted">@bonus</span>. Du har nu gennemført alle nødder for disse kategorier.',
        'theme_completed': 'Tillykke! Du har knækket <strong>alle @rewardTotalCount nødder</strong>. Du har nu gennemført alle nødder for disse kategorier.',
        'first_reward_with_bonus': 'Du har nu knækket <strong>den første nød</strong> og optjent <span class="highlighted">@bonus</span> indtil videre. I morgen venter endnu en nød!',
        'first_reward': 'Du har nu knækket <strong>den første nød</strong>. I morgen venter endnu en nød!',
        'theme_ongoing_with_bonus': 'Du har knækket <strong>@rewardCompletedCount ud af @rewardTotalCount Nødder</strong> og tjent <span class="highlighted">@bonus</span> indtil videre. Åbn en ny nød hver dag!',
        'theme_ongoing': 'Du har knækket <strong>@rewardCompletedCount ud af @rewardTotalCount Nødder</strong>. Åbn en ny nød hver dag!',
        'retake': 'Tag testen igen',
        'nut_of_the_day': 'Dagens nød',
        'themes': 'Kategorier',
        'i_have_an_account': 'Log ind',
        'app_name': 'Hjernesund-testen',
        'connection_msg':'Ingen forbindelse. Hjernesund-testen kræver en internetforbindelse for at fungere.',
        'error_start_test':'Der opstod en fejl ved starten af testen.',
        'short_test_restrict':'Den korte LIBRA -test er ikke tilgængelig, når du er indlogeret.',
        'error_save_theme': 'Der er opstået en fejl under lagring af temaet.',

        'referral_list_work': 'Du arbejder på en sund(ere) hjerne på en sjov måde',
        'referral_list_advice': 'Du får personlige råd',
        'referral_list_subject': 'Du vælger selv hvilket område, du vil arbejde videre med',
        'referral_list_messgae': 'Du modtager et kort tip hver dag med inspiration, personlige challenges,og gode råd',

        'navigation_error':'Der er opståret en navigationsfejl',
        'error_send_answer':'Der er opståret en fejl. Svaret blev ikke sendt.',
        'min_digits': 'Feltet :attribute skal indeholde mindst :min_digits cifre.',
        'min_lowers':'Feltet :attribute skal indeholde mindst :min_lowers små bogstaver.',
        'min_uppers':'Feltet :attribute  skal indeholde mindst :min_uppers store bogstaver.',
        'min_specialchars':'Feltet :attribute  skal indeholde mindst :min_specialchars specialtegn..',
        'min_selected':'Vælg mindst :min_selected svar i :attribute feltet.',
        'max_selected':'Vælg max :max_selected svar i :attribute feltet.',
        'answer': 'svar',

        'form_contains_errors': 'Formularen indeholder følgende fejl:\n',
        'password': 'Adgangskode',
        'form_password_too_short': 'Kodeordet er for kort',
        'form_password_too_long': 'Kodeordet er for langt',
        'form_password_no_upper_case': 'Kodeordet indeholder ikke store bogstaver.',
        'form_password_no_lower_case': 'Kodeordet indeholder ikke små bogstaver.',
        'form_password_no_numeric': 'Kodeordet indeholder inden tal.',
        'form_password_no_special': 'Kodeordet indeholder ingen specailtegn.',

        'chatui_ok': 'Okay',
        'chatui_continue': 'Fortsæt',

        'system_notification_ignore': 'Ignorer',
        'system_notification_view': 'Se',

        'date_days': '@days.',
        'day_sun': 'Søn',
        'day_mon': 'Man',
        'day_tue': 'Tir',
        'day_wed': 'Ons',
        'day_thu': 'Tor',
        'day_fri': 'Fre',
        'day_sat': 'Lør',
        'month_jan': 'Jan',
        'month_feb': 'Feb',
        'month_mar': 'Mar',
        'month_apr': 'Apr',
        'month_may': 'Maj',
        'month_jun': 'Jun',
        'month_jul': 'Jul',
        'month_aug': 'Aug',
        'month_sep': 'Sep',
        'month_oct': 'Okt',
        'month_nov': 'Nov',
        'month_dec': 'Dec',
        'month_full_jan': 'Januar',
        'month_full_feb': 'Februar',
        'month_full_mar': 'Marts',
        'month_full_apr': 'April',
        'month_full_may': 'Maj',
        'month_full_jun': 'Juni',
        'month_full_jul': 'Juli',
        'month_full_aug': 'August',
        'month_full_sep': 'September',
        'month_full_oct': 'Oktober',
        'month_full_nov': 'November',
        'month_full_dec': 'December',
        'datepicker_overlay_button': 'Send',
        'datepicker_overlay_placeholder': 'Indtast et år',
      },
      'no': { //Norsk
        'completed': '',
        'next': '',
        'previous': '',
        'stop_test': '',
        'information': '',
        'back': '',
        'and': '',
        'ready': '',
        'see_your_results': '',
        'close': '',
        'previous_question': '',
        'test_result_improve': '',
        'test_result_good': '',
        'thanks_result_page': '',
        'bonus_singular': '',
        'bonus_plural': '',
        'theme_completed_with_bonus': '',
        'theme_completed': '',
        'first_reward_with_bonus': '',
        'first_reward': '',
        'theme_ongoing_with_bonus': '',
        'theme_ongoing': '',
        'retake': '',
        'change_password': '',
        'request_new_password': '',
        'nut_of_the_day': '',
        'themes': '',
        'i_have_an_account': '',
        'app_name': '',
        'connection_msg':'',
        'error_find_session':'',
        'error_start_session':'',
        'error_start_test':'',
        'short_test_restrict':'',
        'error_test_early_end': '',
        'error_test_start':'',

        'referral_list_work': '',
        'referral_list_advice': '',
        'referral_list_subject': '',
        'referral_list_messgae': '',

        'error_accept_privacy': '',
        'navigation_error':'',
        'error_send_answer':'',
        'min_digits': '',
        'min_lowers':'',
        'min_uppers':'',
        'min_specialchars':'',
        'min_selected':'',
        'max_selected':'',
        'answer': '',

        'form_contains_errors': '',
        'password': '',
        'form_password_too_short': '',
        'form_password_too_long': '',
        'form_password_no_upper_case': '',
        'form_password_no_lower_case': '',
        'form_password_no_numeric': '',
        'form_password_no_special': '',

        'chatui_ok': '',
        'chatui_continue': '',

        'system_notification_ignore': '',
        'system_notification_view': '',

        'date_days': '@days',
        'day_sun': '',
        'day_mon': '',
        'day_tue': '',
        'day_wed': '',
        'day_thu': '',
        'day_fri': '',
        'day_sat': '',
        'month_jan': '',
        'month_feb': '',
        'month_mar': '',
        'month_apr': '',
        'month_may': '',
        'month_jun': '',
        'month_jul': '',
        'month_aug': '',
        'month_sep': '',
        'month_oct': '',
        'month_nov': '',
        'month_dec': '',
        'month_full_jan': '',
        'month_full_feb': '',
        'month_full_mar': '',
        'month_full_apr': '',
        'month_full_may': '',
        'month_full_jun': '',
        'month_full_jul': '',
        'month_full_aug': '',
        'month_full_sep': '',
        'month_full_oct': '',
        'month_full_nov': '',
        'month_full_dec': '',
        'datepicker_overlay_button': '',
        'datepicker_overlay_placeholder': '',
      }

      // 'NL': {
      //   'completed': 'Voltooid',
      //   'next': 'Volgende',
      //   'previous': 'Vorige',
      //   'stop_test': 'Stoppen met de test',
      //   'information': 'Informatie',
      //   'back': 'Terug',
      //   'and': 'en',
      //   'test_result_improve': 'Uit jouw snelle test blijkt dat er ruimte voor verbetering is op het gebied van',
      //   'test_result_good': 'Uit de snelle test blijkt dat je goed bezig bent. De MijnBreincoach app helpt je om je hersengezondheid op peil te houden.',
      //   'system_unknown_error': 'Beim Start der App ist während der Phase "@stage" ein schwerwiegender Fehler aufgetreten.'
      // },
      // 'fr': {
      //   'system_no_connection': 'La connexion internet a été interrompue. Pour bien fonctionner, Myinlife a besoin d\'une connexion internet.',
      //   'system_button_ok': 'OK',
      //   'system_button_cancel': 'Annulez',
      //   'system_dialog_no': 'Non',
      //   'system_dialog_yes': 'Oui',
      //   'system_session_expired': 'Votre session a expiré. Vous devez vous reconnecter pour des raions de sécurité.',
      //   'system_no_translations': 'Les traductions n\'ont pas pu être chargées.',
      //   'system_internet_required': 'Une connexion internet est nécessaire pour Myinlife.',
      //   'system_timeout': 'La demande a expiré. Vérifiez votre connexion Internet et réessayez.',
      //   'system_unknown_error': 'Une erreur fatale s\'est produite lors de la tentative de démarrage de l\'application, lors de l\'étape "@stage".'
      // }
    };

    this.language = App.intLang;
  }

  loadFromData(data) {
    this.strings = data;
  }

  setLanguage(lang) {
    this.language = lang;
  }

  get(key, options) {
    if (!this.strings[this.language]) {
      return '{' + this.language + ':' + key + '}';
    }

    let trans = this.strings[this.language][key];
    if (trans === undefined || trans === '') {
      return '{' + this.language + ':' + key + '}';
    }

    for (let option in options) {
      trans = trans.replace(new RegExp('@' + option, 'g'), options[option]);
    }

    return trans;
  }

  getPreferredLanguage() {
    let i18n = this;

    if (navigator.globalization === undefined) {
      console.warn('No globalization support. Using en as preferred language.');

      return Promise.resolve('en');
    }

    return new Promise(function(resolve, reject) {
      navigator.globalization.getPreferredLanguage(
        function (language) {
          let lang = i18n.language;
          let sub = language.value.substr(0, 2);

          if (sub == 'fr' || sub == 'de' || sub == 'nl' || sub == 'en') {
            lang = sub;
          } else {
            console.warn('Unknown preferred language ' + language.value + '. Using ' + i18n.language + ' as preferred language.');
          }

          resolve(lang);
        },
        function() {
          console.warn('Could not get preferred language. Using ' + i18n.language + ' as preferred language.');
          resolve(i18n.language);
        }
      );
    });
  }

  checkIntegrity() {
    // Determine union of all language keys.
    let allKeys = [];
    for (let lang in this.strings) {
      for (let key in this.strings[lang]) {
        if (!allKeys.includes(key)) {
          allKeys.push(key);
        }
      }
    }

    // Now check which language is missing keys.
    for (let lang in this.strings) {
      let keyCount = 0;
      for (let key in this.strings[lang]) {
        keyCount++;
      }
      console.log('language: ' + lang + ', ' + keyCount + ' strings');

      let missingKeys = [];
      for (let i in allKeys) {
        let key = allKeys[i];
        if (typeof this.strings[lang][key] === 'undefined' || this.strings[lang][key] === '') {
          missingKeys.push(key);
        }
      }

      console.log('missing keys: ' + missingKeys.join(', '));
    }
  }
}

export default new I18n();
