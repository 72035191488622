'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    return 'v2/config';
  },

  defaults: {
    themeCategories: [],
    privacyStatementUrl: '',
    loginIntro: ''
  }

});